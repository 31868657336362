export default {
  "ATTENTION: without the unique return code, your package cannot be identified.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATTENZIONE: senza il codice di reso unico, il tuo pacco non può essere identificato."])},
  "Bank account number starts with %s (example: %s).": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di conto bancario inizia con %s (esempio: %s)."])},
  "Be sure to include your unique complaint number with the product in the package. Your unique complaint number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurati di includere il tuo numero di reclamo univoco nel prodotto nella confezione. Il tuo numero di reclamo univoco è"])},
  "Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
  "Damaged product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotto danneggiato"])},
  "Greetings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saluti"])},
  "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciao!"])},
  "Information is not required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le informazioni non sono richieste"])},
  "No reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non c'è motivo"])},
  "Once your return has been received at our offices, an e-mail will be sent to the address entered in the complaint form where we will inform you about the latest updates on the return process.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una volta ricevuto il reso presso i nostri uffici, verrà inviata un'e-mail all'indirizzo inserito nel modulo di reclamo dove ti informeremo sugli ultimi aggiornamenti sulla procedura di reso."])},
  "Order number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero d'ordine"])},
  "Paid on our website, and not with the delivery service / courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagato sul nostro sito Web e non con il servizio di consegna/corriere"])},
  "Product quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualità del prodotto"])},
  "Product replacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sostituzione del prodotto"])},
  "Product return procedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedura per la restituzione"])},
  "Product return process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processo di restituzione del prodotto"])},
  "Reason for the complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo del reclamo"])},
  "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimborso"])},
  "Refund form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulo di rimborso"])},
  "Returning the product is quick and easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La restituzione del prodotto è semplice e veloce"])},
  "Returning the product is quick and easy.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La restituzione del prodotto è semplice e veloce"])},
  "STEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passo"])},
  "Select type of complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il tipo di reclamo"])},
  "Send the package to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia il pacco a"])},
  "Send the package to the following address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spedisci il pacco al seguente indirizzo:"])},
  "Something went wrong, try again later or contact our support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualcosa è andato storto, riprova più tardi o contatta il nostro supporto"])},
  "Submit the form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviare il Modulo"])},
  "The bank account number starts with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di conto bancario inizia con %s (esempio: %s)."])},
  "The return procedure has just been forwarded to your e-mail address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La procedura di reso è stata appena inoltrata al tuo indirizzo e-mail:"])},
  "Together with the product to be returned, enter your unique return code in the package.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insieme al prodotto da restituire, inserisci il tuo codice di reso univoco nel pacco.\n"])},
  "Type of payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di pagamento"])},
  "When we receive the package in our branch, you will be informed about the course of the complaint to your e-mail address, which you provided when submitting the complaint form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando riceveremo il pacco nella nostra filiale, sarai informato sull'andamento del reclamo al tuo indirizzo e-mail, che hai fornito al momento della presentazione del modulo di reclamo"])},
  "Wrong product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotto sbagliato"])},
  "Wrong quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità errata"])},
  "You can print out already completed information to help return the product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile stampare le informazioni già completate per aiutare a restituire il prodotto"])},
  "You have already submitted a return request. Be patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai già inviato una richiesta di reso. Sii paziente"])},
  "You have received a PDF file for return procedure in the attachment.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai ricevuto un file PDF per la procedura di reso in allegato."])},
  "Your unique return number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo numero di reso unico è"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
  "complaint number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numero reclamo"])},
  "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esempio"])},
  "first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istruzioni"])},
  "last name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
  "post code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice postale"])},
  "reciever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ricevente"])},
  "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittente"])}
}